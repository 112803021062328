import React, {FC, Fragment, useContext, useState, useCallback} from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import safeNavigate from '../../utils/safeNavigate';
import {UserContext, StatusForm} from '../../porter-package';
import AccountContainer from '../../containers/AccountContainer';

const Account: FC = () => {
	const context = useContext(UserContext);
	if (!context.isLoggedIn) {
		safeNavigate('/log-in/');
		return null;
	}

	const [status, setStatus] = useState<boolean | undefined>(undefined);
	const handleStatusComplete = useCallback(
		(result) => {
			setStatus(result);
		},
		[status, setStatus]
	);

	return <AccountContainer>
		<Fragment>
			<Card>
				<Card.Body>
					<Card.Title>
						My Account
					</Card.Title>
					<Card.Text>
						Welcome {context.profile?.user.firstName}!
					</Card.Text>
				</Card.Body>
			</Card>
			<Card>
				<Card.Body>
					<Card.Title>
						Status
					</Card.Title>
					<Card.Subtitle className="mb-2 text-muted">
						{
							status === false ?
								'Please complete all steps to complete your account.' :
								status === true ?
									'Your account is setup and ready to go!' :
									'Loading ...'
						}
					</Card.Subtitle>
					<Card.Text>
						{
							status === undefined ?
								<Spinner
									animation="border"
									role="status" /> :
								null
						}
						<StatusForm onComplete={handleStatusComplete} />
					</Card.Text>
				</Card.Body>
			</Card>
		</Fragment>
	</AccountContainer >;
};

export default Account;
